const Lightning = () => {
  return (
    <div
      style={{
        backgroundColor: "#ECF4F3",
        borderRadius: "3px",
        height: "40px",
        width: "40px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M11.6667 36.6667L18.3333 24.1667L5 22.5L25 3.33337H28.3333L21.6667 15.8334L35 17.5L15 36.6667H11.6667ZM20.9167 26.375L27.625 19.9584L16.4167 18.5417L19.0417 13.6667L12.375 20.0834L23.5417 21.4584L20.9167 26.375Z"
          fill="#265660"
        />
      </svg>
    </div>
  );
};

export default Lightning;
