import Header from "./components/Header";
import StartPage from "./components/StartPage";
import VaareTjenester from "./components/VaareTjenester/VaareTjenester";
import OmOss from "./components/OmOss/OmOss";
import SnakkeSammen from "./components/SnakkeSammen/SnakkeSammen";
import Sertifiseringer from "./components/Sertifiseringer/Sertifiseringer";
import { StylingContextProvider } from "./contexts/StylingContext";

const App = () => {
  return (
    <StylingContextProvider>
      <div className="App" style={{ fontFamily: "Inter, sand-sarif" }}>
        <Header />
        <div
          style={{
            display: "grid",
            gap: window.innerWidth < 440 ? "40px" : "80px",
          }}
        >
          <StartPage />
          <VaareTjenester />
          <OmOss />
          <SnakkeSammen />
          <Sertifiseringer />
        </div>
        <div
          style={{
            borderTop: "1px solid #E4E4E4",
            marginBottom: "24px",
            marginTop: window.innerWidth < 440 ? "40px" : "80px",
            boxSizing: "border-box"
          }}
        ></div>
        <p
          style={{
            color: "#202020",
            fontWeight: 300,
            margin: 0,
            marginBottom: "40px",
            textAlign: "center",
          }}
        >
          Bolig, takst og tegning AS
        </p>
      </div>
    </StylingContextProvider >
  );
};

export default App;
