import {
  createContext,
  MutableRefObject,
  ReactNode,
  useRef,
  useState,
} from "react";

export type StylingContextType = {
  mobile: boolean;
  contactRef: MutableRefObject<HTMLDivElement>;
  serviceRef: MutableRefObject<HTMLDivElement>;
};

const StylingContext = createContext<StylingContextType>(
  {} as StylingContextType,
);
export default StylingContext;
export const StylingContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [mobile] = useState<boolean>(window.innerWidth < 440);
  const contactRef = useRef<HTMLDivElement>(null);
  const serviceRef = useRef<HTMLDivElement>(null);

  const value = {
    mobile,
    contactRef,
    serviceRef,
  };
  return (
    <StylingContext.Provider value={value}>{children}</StylingContext.Provider>
  );
};
