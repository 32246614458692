/** @jsxImportSource @emotion/react */
import Sertifsering from "./Sertifisering";
import { useContext } from "react";
import StylingContext from "../../contexts/StylingContext";

const Sertifiseringer = () => {
  const { mobile } = useContext(StylingContext);

  const layoutStyles = {
    display: "grid",
    justifyItems: "center",
    "@media only screen and (min-width: 0px)": {
      gap: mobile ? "20px" : "28px",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    "@media only screen and (min-width: 1150px)": {
      gap: "28px",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  };
  const layoutPaddingStyles = {
    "@media only screen and (min-width: 0px)": {
      padding: "8px",
    },
    "@media only screen and (min-width: 1150px)": {
      padding: "24px",
    },
    "@media only screen and (min-width: 1920px)": {
      padding: "85px",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div css={layoutPaddingStyles}></div>
      <div css={layoutStyles}>
        <Sertifsering
          image="sentraltGodkjent"
          paragraph={
            <p
              style={{
                color: "#3B3B3B",
                fontSize: `${mobile ? 16 : 18}px`,
                fontWeight: 300,
                margin: 0,
                padding: mobile ? "0px 32px" : "0px",
                textAlign: mobile ? "center" : "start",
              }}
            >
              Sentral godkjenning fra Direktoratet for Byggkvalitet
            </p>
          }
        />
        <Sertifsering
          image="bmtf"
          paragraph={
            <p
              style={{
                color: "#3B3B3B",
                fontSize: `${mobile ? 16 : 18}px`,
                fontWeight: 300,
                margin: 0,
                padding: mobile ? "0px 32px" : "0px",
                textAlign: mobile ? "center" : "start",
              }}
            >
              Medlem av norges eldste takseringsforbund: Byggmestrenes
              Takseringsforbund
            </p>
          }
        />
        <Sertifsering
          image="byggMester"
          paragraph={
            <p
              style={{
                color: "#3B3B3B",
                fontSize: `${mobile ? 16 : 18}px`,
                fontWeight: 300,
                margin: 0,
                textAlign: mobile ? "center" : "start",
              }}
            >
              Godkjent Mesterbedrift
            </p>
          }
        />
        <Sertifsering
          image="enova"
          paragraph={
            <p
              style={{
                color: "#3B3B3B",
                fontSize: `${mobile ? 16 : 18}px`,
                fontWeight: 300,
                margin: 0,
                textAlign: mobile ? "center" : "start",
              }}
            >
              Godkjent Enovarådgivere
            </p>
          }
        />
      </div>
      <div css={layoutPaddingStyles}></div>
    </div>
  );
};

export default Sertifiseringer;
