/** @jsxImportSource @emotion/react */
import { ReactNode, useContext, useEffect, useState } from "react";
// @ts-ignore
import byggMester from "../../images/byggMester.png";
// @ts-ignore
import sentraltGodkjent from "../../images/sentraltGodkjent.png";
// @ts-ignore
import bmtf from "../../images/bmtf.png";
// @ts-ignore
import enova from "../../images/enova.png";
import StylingContext from "../../contexts/StylingContext";
type SertifiseringProp = {
  image: string;
  paragraph: ReactNode;
};

const Sertifsering = ({ image, paragraph }: SertifiseringProp) => {
  const [correctLogo, setCorrectLogo] = useState<string>("");
  const { mobile } = useContext(StylingContext);
  const layoutStyles = {
    backgroundColor: "#ECF4F3",
    borderRadius: mobile ? "12px" : "20px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: mobile ? "column" : "row",
    padding: mobile ? "20px" : "40px",
    alignItems: "center",
    maxHeight: "275px",
    width: "100%",
    "@media only screen and (min-width: 0px)": {
      maxWidth: "360px",
    },
    "@media only screen and (min-width: 1150px)": {
      maxWidth: "475px",
    },
    "@media only screen and (min-width: 1920px)": {
      maxWidth: "660px",
    },
  };

  const imageStyle = {
    marginRight: mobile ? "0px" : "24px",
    marginBottom: mobile ? "24px" : "0px",
    height: "100%",
    width: "100%",
    "@media only screen and (min-width: 0px)": {
      maxHeight: "190px",
      maxWidth: "190px",
      minHeight: "190px",
      minWidth: "190px",
    },
    "@media only screen and (min-width: 1150px)": {
      maxHeight: "135px",
      maxWidth: "135px",
      minHeight: "135px",
      minWidth: "135px",
    },
    "@media only screen and (min-width: 1920px)": {
      maxHeight: "190px",
      maxWidth: "190px",
      minHeight: "190px",
      minWidth: "190px",
    },
  };

  useEffect(() => {
    if (image === "byggMester") {
      setCorrectLogo(byggMester);
    } else if (image === "sentraltGodkjent") {
      setCorrectLogo(sentraltGodkjent);
    } else if (image === "bmtf") {
      setCorrectLogo(bmtf);
    } else if (image === "enova") {
      setCorrectLogo(enova);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div css={layoutStyles}>
      <img alt="logo" src={correctLogo} css={imageStyle} />
      {paragraph}
    </div>
  );
};

export default Sertifsering;
