const Location = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16.0007 16.0001C16.734 16.0001 17.3618 15.739 17.884 15.2167C18.4062 14.6945 18.6673 14.0667 18.6673 13.3334C18.6673 12.6001 18.4062 11.9723 17.884 11.4501C17.3618 10.9279 16.734 10.6667 16.0007 10.6667C15.2673 10.6667 14.6395 10.9279 14.1173 11.4501C13.5951 11.9723 13.334 12.6001 13.334 13.3334C13.334 14.0667 13.5951 14.6945 14.1173 15.2167C14.6395 15.739 15.2673 16.0001 16.0007 16.0001ZM16.0007 25.8001C18.7118 23.3112 20.7229 21.0501 22.034 19.0167C23.3451 16.9834 24.0007 15.1779 24.0007 13.6001C24.0007 11.1779 23.2284 9.19453 21.684 7.65008C20.1395 6.10564 18.2451 5.33341 16.0007 5.33341C13.7562 5.33341 11.8618 6.10564 10.3173 7.65008C8.77287 9.19453 8.00065 11.1779 8.00065 13.6001C8.00065 15.1779 8.65621 16.9834 9.96732 19.0167C11.2784 21.0501 13.2895 23.3112 16.0007 25.8001ZM16.0007 29.3334C12.4229 26.289 9.75065 23.4612 7.98398 20.8501C6.21732 18.239 5.33398 15.8223 5.33398 13.6001C5.33398 10.2667 6.40621 7.61119 8.55065 5.63341C10.6951 3.65564 13.1784 2.66675 16.0007 2.66675C18.8229 2.66675 21.3062 3.65564 23.4507 5.63341C25.5951 7.61119 26.6673 10.2667 26.6673 13.6001C26.6673 15.8223 25.784 18.239 24.0173 20.8501C22.2507 23.4612 19.5784 26.289 16.0007 29.3334Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default Location;
