const Edit = () => {
  return (
    <div
      style={{
        backgroundColor: "#ECF4F3",
        borderRadius: "3px",
        height: "40px",
        width: "40px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M23.3327 36.6667V31.5417L32.541 22.375C32.791 22.125 33.0688 21.9445 33.3743 21.8334C33.6799 21.7223 33.9855 21.6667 34.291 21.6667C34.6243 21.6667 34.9438 21.7292 35.2493 21.8542C35.5549 21.9792 35.8327 22.1667 36.0827 22.4167L37.6243 23.9584C37.8466 24.2084 38.0202 24.4862 38.1452 24.7917C38.2702 25.0973 38.3327 25.4028 38.3327 25.7084C38.3327 26.0139 38.2771 26.3264 38.166 26.6459C38.0549 26.9653 37.8743 27.25 37.6243 27.5L28.4577 36.6667H23.3327ZM25.8327 34.1667H27.416L32.4577 29.0834L31.7077 28.2917L30.916 27.5417L25.8327 32.5834V34.1667ZM9.99935 36.6667C9.08268 36.6667 8.29796 36.3403 7.64518 35.6875C6.9924 35.0348 6.66602 34.25 6.66602 33.3334V6.66671C6.66602 5.75004 6.9924 4.96532 7.64518 4.31254C8.29796 3.65976 9.08268 3.33337 9.99935 3.33337H23.3327L33.3327 13.3334V18.3334H29.9993V15H21.666V6.66671H9.99935V33.3334H19.9993V36.6667H9.99935ZM31.7077 28.2917L30.916 27.5417L32.4577 29.0834L31.7077 28.2917Z"
          fill="#265660"
        />
      </svg>
    </div>
  );
};

export default Edit;
