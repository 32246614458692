/** @jsxImportSource @emotion/react */
import { useContext } from "react";
import StylingContext from "../../contexts/StylingContext";
import Hus from "./Hus";

const OmOss = () => {
  const { mobile } = useContext(StylingContext);

  const layoutStyle = {
    backgroundColor: "#FEF9EF",
    borderRadius: mobile ? "12px" : "40px",
    display: "grid",
    justifyItems: "center",
    "@media only screen and (min-width: 0px)": {
      gap: "28px",
      padding: "32px 24px",
      gridTemplateColumns: "repeat(1, 1fr)",
      maxWidth: "1500px",
      minWidth: "250px",
    },
    "@media only screen and (min-width: 1150px)": {
      gap: "42px",
      padding: "60px 60px",
      gridTemplateColumns: "repeat(2, 1fr)",
      maxWidth: "1146px",
    },
    "@media only screen and (min-width: 1920px)": {
      gap: "56px",
      padding: "60px 60px",
      gridTemplateColumns: "repeat(2, 1fr)",
      maxWidth: "1518px",
    },
  };
  const layoutPaddingStyles = {
    "@media only screen and (min-width: 0px)": {
      padding: "8px",
    },
    "@media only screen and (min-width: 1150px)": {
      padding: "24px",
    },
    "@media only screen and (min-width: 1920px)": {
      padding: "24px",
    },
  };
  const titleStyle = {
    color: "#202020",
    fontWeight: 800,
    margin: 0,
    "@media only screen and (min-width: 0px)": {
      fontSize: "28px",
    },
    "@media only screen and (min-width: 440px)": {
      fontSize: "36px",
    },
    "@media only screen and (min-width: 1150px)": {
      fontSize: "44px",
    },
    "@media only screen and (min-width: 1450px)": {
      fontSize: "44px",
    },
  };

  const paragraphStyle = {
    color: "#3B3B3B",
    fontSize: `${mobile ? 18 : 21}px`,
    fontWeight: 300,
    lineHeight: "28px",
    margin: 0,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div css={layoutPaddingStyles}></div>
      <div css={layoutStyle}>
        <div>
          <p css={titleStyle}>Om Bolig, takst & tegning</p>
          <div style={{ padding: mobile ? "10px" : "20px" }}></div>
          <p css={paragraphStyle}>
            Bolig, takst og tegning AS drives av Magnus Thunshelle og Christer
            Stokka. Til sammen besitter vi bred kompetanse innenfor de ulike
            prosessene, samt hele – den totale prosessen – som genereres av det
            å ha en bolig eller et ønske om å utvikle en bolig eller et
            boligområde. <br></br>
            <br></br>Vår kompetanse dekker alt fra boligtakst, søknadstjenester,
            tegneoppdrag og energirådgivning.
          </p>
        </div>
        <Hus />
      </div>
      <div css={layoutPaddingStyles}></div>
    </div>
  );
};

export default OmOss;
