const Compass = () => {
  return (
    <div
      style={{
        backgroundColor: "#ECF4F3",
        borderRadius: "3px",
        display: "block",
        height: "40px",
        width: "40px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M11.2507 35L10.834 31.3333L15.584 18.25C16.0007 18.6389 16.452 18.9653 16.9382 19.2292C17.4243 19.4931 17.9451 19.6944 18.5007 19.8333L13.9173 32.4167L11.2507 35ZM28.7507 35L26.084 32.4167L21.5007 19.8333C22.0562 19.6944 22.577 19.4931 23.0632 19.2292C23.5493 18.9653 24.0007 18.6389 24.4173 18.25L29.1673 31.3333L28.7507 35ZM20.0007 18.3333C18.6118 18.3333 17.4312 17.8472 16.459 16.875C15.4868 15.9028 15.0007 14.7222 15.0007 13.3333C15.0007 12.25 15.3132 11.2847 15.9382 10.4375C16.5632 9.59028 17.3618 9 18.334 8.66667V5H21.6673V8.66667C22.6395 9 23.4382 9.59028 24.0632 10.4375C24.6882 11.2847 25.0007 12.25 25.0007 13.3333C25.0007 14.7222 24.5145 15.9028 23.5423 16.875C22.5701 17.8472 21.3895 18.3333 20.0007 18.3333ZM20.0007 15C20.4729 15 20.8687 14.8403 21.1882 14.5208C21.5076 14.2014 21.6673 13.8056 21.6673 13.3333C21.6673 12.8611 21.5076 12.4653 21.1882 12.1458C20.8687 11.8264 20.4729 11.6667 20.0007 11.6667C19.5284 11.6667 19.1326 11.8264 18.8132 12.1458C18.4937 12.4653 18.334 12.8611 18.334 13.3333C18.334 13.8056 18.4937 14.2014 18.8132 14.5208C19.1326 14.8403 19.5284 15 20.0007 15Z"
          fill="#265660"
        />
      </svg>
    </div>
  );
};

export default Compass;
