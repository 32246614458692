/** @jsxImportSource @emotion/react */
// @ts-ignore
import house from "../../images/house.png";

const Hus = () => {

  return (
    <div>
      <img alt="logo" src={house} height={"100%"} width={"100%"}></img>
    </div>
  );
};

export default Hus;
