/** @jsxImportSource @emotion/react */
import StylingContext from "../../contexts/StylingContext";
import { useContext, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Spinner from "../icons/Spinner";

const KontaktSkjema = () => {
  const { mobile } = useContext(StylingContext);
  const [formSent, setFormSent] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const schemaLayoutStyles = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "@media only screen and (min-width: 0px)": {
      padding: "0px 16px",
    },
    "@media only screen and (min-width: 1150px)": {
      padding: "0px 50px 0px 50px",
    },
    "@media only screen and (min-width: 1450px)": {
      padding: "0px 75px 0px 75px",
    },
    "@media only screen and (min-width: 1920px)": {
      padding: "0px 100px 0px 100px",
    },
  };

  const inputStyle = {
    backgroundColor: "#265660",
    borderRadius: "4px",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box",
    color: "#FFFFFF",
    height: "40px",
    fontSize: `${mobile ? 16 : 18}px`,
    padding: "8px 12px",
    outline: "none",
    width: "100%",
  };

  const titleStyle = {
    color: "#FFFFFF",
    margin: 0,
  };

  const inputTitleStyle = {
    color: "#FFFFFF",
    margin: 0,
    fontSize: `${mobile ? 16 : 18}px`,
    marginBottom: "6px",
  };

  const buttonStyle = {
    backgroundColor: "#FFFFFF",
    borderRadius: "100px",
    border: "none",
    color: "#265660",
    cursor: "pointer",
    fontSize: `${mobile ? 21 : 24}px`,
    height: "48px",
    width: "100%",
    ":hover": {
      backgroundColor: "#ECF4F3",
    },
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (userEmail === "" || message === "") {
      alert(
        "Du kan ikke sende oss en epost uten en melding og en måte vi kan svare deg på.",
      );
    } else {
      setFormSent(true);
      emailjs
        .sendForm("service_hcm9la6", "template_5gvkvqs", form.current, {
          publicKey: "1VGChoZDkPtQEgapI",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            alert("Takk for at du kontaktet oss!");
            setFormSent(false);
            setUserEmail("");
          },
          (error) => {
            console.log("FAILED...", error.text);
            alert("Oops, noe gikk galt når du prøvde å kontakte oss.");
            setFormSent(false);
          },
        );
    }
  };

  return (
    <div css={schemaLayoutStyles}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <p
          css={titleStyle}
          style={{
            fontWeight: 600,
            fontSize: `${mobile ? 21 : 24}px`,
          }}
        >
          Send oss en melding
        </p>
        <form ref={form}>
          <div style={{ padding: "12px" }}></div>
          <p css={inputTitleStyle}>Navn</p>
          <input css={inputStyle} type="text" name="user_name" />
          <div style={{ padding: "12px" }}></div>
          <p css={inputTitleStyle}>E-post *</p>
          <input
            css={inputStyle}
            type="email"
            name="user_email"
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <div style={{ padding: "12px" }}></div>
          <p css={inputTitleStyle}>Telefon</p>
          <input css={inputStyle} type="text" name="user_phonenumber" />
          <div style={{ padding: "12px" }}></div>
          <p css={inputTitleStyle}>Melding *</p>
          <textarea
            css={inputStyle}
            style={{
              fontFamily: "Inter, sand-sarif",
              minHeight: "124px",
              resize: "vertical",
            }}
            name="message"
            onChange={(e) => setMessage(e.target.value)}
          />
          <div style={{ padding: "20px" }}></div>
          <button css={buttonStyle} onClick={sendEmail}>
            {formSent ? <Spinner /> : "Send"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default KontaktSkjema;
