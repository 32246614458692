type OurServices = {
  boligTakst: string;
  tegneoppdrag: string;
  soknadstjenester: string;
  energiraadgivning: string;
};

export type PageTextType = {
  ourServices: OurServices;
};

const PageText: PageTextType = {
  ourServices: {
    boligTakst:
      "Som takstmenn tilbyr vi et bredt spekter av tjenester. Dette inkluderer boligtakst, eierskifterapport (tilstandsrapport), verdi- og lånetakst og overtakelse- og ferdighetsbefaringer. Vi utfører også reklamasjonstakst, forhåndstakst og bygg(e)oppfølging. Vår ekspertise omfatter skadetaksering, uavhengig kontroll, samt byggelånskontroll, for å sikre en omfattende og kvalitetssikker tjeneste for alle våre kunder.",
    tegneoppdrag:
      "Vi håndterer et bredt spekter av tegneoppdrag – fra de første skissene av enten nye eller eksisterende prosjekter – til detaljerte prosjektunderlag og videre avanserte 3D-modelleringer.",
    soknadstjenester:
      "Vi tar hånd om hele søkeprosessen for byggeprosjekter. Dette innebærer at vi tar ansvar for alt fra nabovarsling til utstedelse av ferdigattest. Dette gjelder for eksempel ett-trinns søknader og rammesøknader.",
    energiraadgivning:
      "Som Enovarådgivere utfører vi alt fra attest til rådgivning og prosjektoppfølging med ferdigstillelse av prosjekt. Vi sikrer at din boligoppgradering oppfyller dagens energikrav, noe som potensielt kan kvalifisere for betydelige støttebeløp fra Enova. For mer informasjon vedrørende kvalifikasjoner og støttebeløp – besøk: www.enova.no. ",
  },
};

export default PageText;
