/** @jsxImportSource @emotion/react */
// @ts-ignore
import working from "../images/startPage.png";
import StylingContext from "../contexts/StylingContext";
import { useContext } from "react";

const StartPage = () => {
  const { mobile } = useContext(StylingContext);
  const titleLayoutStyles = {
    color: "#202020",
    fontStyle: "normal",
    fontWeight: 800,
    textAlign: "center",
    "@media only screen and (min-width: 0px)": {
      fontSize: `${mobile ? 30 : 44}px`,
      padding: "0px 16px",
    },
    "@media only screen and (min-width: 1150px)": {
      fontSize: "52px",
      padding: "0px 40px 0px 40px",
    },
    "@media only screen and (min-width: 1450px)": {
      fontSize: "60px",
      padding: "0px 160px 0px 160px",
    },
    "@media only screen and (min-width: 1920px)": {
      fontSize: "68px",
      padding: "0px 350px 0px 350px",
    },
  };

  const imageLayoutStyles = {
    background: "linear-gradient(to bottom, #ECF4F3 50%, #FFFFFF 50%)",
    display: "flex",
    justifyContent: "center",
    "@media only screen and (min-width: 0px)": {
      padding: "0px 16px",
    },
    "@media only screen and (min-width: 1150px)": {
      padding: "0px 40px 0px 40px",
    },
    "@media only screen and (min-width: 1450px)": {
      padding: "0px 325px 0px 325px",
    },
    "@media only screen and (min-width: 1920px)": {
      padding: "0px 425px 0px 425px",
    },
  };
  return (
    <div
      style={{
        backgroundColor: "#ECF4F3",
      }}
    >
      <div css={titleLayoutStyles}>
        <div>
          Solid kompetanse og rådgivning innen&nbsp;
          <span
            style={{
              color: "#265660",
              display: "inline-block",
              textDecoration: "underline",
            }}
          >
            takst
          </span>
          &nbsp;og&nbsp;
          <span
            style={{
              color: "#265660",
              display: "inline-block",
              textDecoration: "underline",
            }}
          >
            tegning
          </span>
          &nbsp;av bolig
        </div>
      </div>
      <div style={{ padding: mobile ? "20px" : "40px" }}></div>
      <div css={imageLayoutStyles}>
        <img alt="working" src={working} height={"100%"} width={"100%"} />
      </div>
    </div>
  );
};

export default StartPage;
