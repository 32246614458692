import StylingContext from "../../contexts/StylingContext";
import { useContext } from "react";

/** @jsxImportSource @emotion/react */
type TjenesteKortProps = {
  icon: React.ReactNode;
  text: string;
  title: string;
};

const TjenesteKort = ({ icon, text, title }: TjenesteKortProps) => {
  const { mobile } = useContext(StylingContext);
  const containsEnovaLink = text.includes("www.enova.no")
  const textWithOutEnova = text.replace(" www.enova.no.", "")
  const layoutStyles = {
    backgroundColor: "#fff",
    borderRadius: mobile ? "12px" : "20px",
    maxHeight: "520px",
    padding: mobile ? "16px" : "32px",
    "@media only screen and (min-width: 0px)": {
      maxWidth: "1500px",
      minWidth: "250px",
    },
    "@media only screen and (min-width: 1150px)": {
      maxWidth: "550px",
    },
    "@media only screen and (min-width: 1920px)": {
      maxWidth: "328px",
    },
  };

  return (
    <div css={layoutStyles}>
      {icon}
      <p
        style={{
          color: "#141414",
          fontSize: `${mobile ? 21 : 24}px`,
          fontWeight: 600,
          margin: 0,
          lineHeight: "32px",
          marginBottom: "12px",
          marginTop: mobile ? "12px" : "28px",
        }}
      >
        {title}
      </p>
      <p
        style={{
          color: "#3B3B3B",
          fontSize: `${mobile ? 16 : 18}px`,
          fontWeight: 300,
          lineHeight: "28px",
          margin: 0,
        }}
      >
        {textWithOutEnova}

        {containsEnovaLink && (
          <a style={{
            color: "#265660",
            fontSize: `${mobile ? 16 : 18}px`,
            fontWeight: 500,
            lineHeight: "28px",
            margin: 0,
          }} href="https://www.enova.no/" target="blank" >Enova</a>
        )}
      </p>


      {text.includes("www.enova.no")}
    </div>
  );
};

export default TjenesteKort;
