/** @jsxImportSource @emotion/react */
// @ts-ignore
import logo from "../images/logo.png";
import StylingContext from "../contexts/StylingContext";
import { useContext } from "react";

const Header = () => {
  const { contactRef, serviceRef } = useContext(StylingContext);
  const headerStyles = {
    backgroundColor: "#ECF4F3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (hover: hover) and (pointer: fine)": {
      "button:hover": {
        backgroundColor: "#265660",
        color: "#fff",
      },
    },
    ".serviceButton": {
      "@media only screen and (max-width: 440px)": {
        marginLeft: "34px",
        order: 1,
      },
    },
    ".contactButton": {
      "@media only screen and (max-width: 440px)": {
        order: 2,
      },
    },
    "@media only screen and (min-width: 0px)": {
      padding: "0px 16px 0px 16px",
      paddingBottom: "60px",
      paddingTop: "24px",
    },
    "@media only screen and (min-width: 1150px)": {
      padding: "0px 48px 0px 48px",
      paddingBottom: "100px",
      paddingTop: "24px",
    },
    "@media only screen and (min-width: 1450px)": {
      padding: "0px 56px 0px 56px",
      paddingBottom: "100px",
      paddingTop: "24px",
    },
    "@media only screen and (min-width: 1920px)": {
      padding: "0px 64px 0px 64px",
      paddingBottom: "100px",
      paddingTop: "24px",
    },
  };

  const buttonStyles = {
    alignItems: "center",
    backgroundColor: "#ECF4F3",
    border: "1px solid #000",
    color: "#202020",
    cursor: "pointer",
    borderRadius: "100px",
    "@media only screen and (min-width: 0px)": {
      fontSize: "16px",
      minWidth: "96px",
      height: "28px",
      padding: "0px 12px",
    },
    "@media only screen and (min-width: 440px)": {
      fontSize: "16px",
      minWidth: "96px",
      height: "36px",
      padding: "4px 12px",
    },
    "@media only screen and (min-width: 1150px)": {
      fontSize: "18px",
      height: "42px",
      minWidth: "110px",
      padding: "8px 16px",
    },
    "@media only screen and (min-width: 1450px)": {
      fontSize: "24px",
      height: "54px",
      minWidth: "150px",
      padding: "12px 24px",
    },
  };

  return (
    <div css={headerStyles}>
      <button
        className="serviceButton"
        css={buttonStyles}
        onClick={() => {
          if (serviceRef.current !== null) {
            serviceRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
      >
        Tjenester
      </button>
      <img
        alt="logo"
        src={logo}
        style={{
          maxHeight: "69px",
          maxWidth: "224px",
          minHeight: "36px",
          minWidth: "118px",
          height: "35%",
          width: "35%",
        }}
      ></img>
      <button
        className="contactButton"
        css={buttonStyles}
        onClick={() => {
          if (contactRef.current !== null) {
            contactRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
      >
        Kontakt
      </button>
    </div>
  );
};

export default Header;
