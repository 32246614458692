/** @jsxImportSource @emotion/react */

import TjenesteKort from "./TjenesteKort";
import House from "../icons/House";
import Lightning from "../icons/Lightning";
import Edit from "../icons/Edit";
import Compass from "../icons/Compass";
import pageText from "../../data/pageText";
import StylingContext from "../../contexts/StylingContext";
import { useContext } from "react";

const VaareTjenester = () => {
  const { mobile, serviceRef } = useContext(StylingContext);
  const layoutStyles = {
    display: "grid",
    justifyItems: "center",
    "@media only screen and (min-width: 0px)": {
      gap: mobile ? "20px" : "28px",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    "@media only screen and (min-width: 1150px)": {
      gap: "28px",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media only screen and (min-width: 1920px)": {
      gap: "28px",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  };
  const layoutPaddingStyles = {
    "@media only screen and (min-width: 0px)": {
      padding: "8px",
    },
    "@media only screen and (min-width: 1150px)": {
      padding: "24px",
    },
    "@media only screen and (min-width: 1920px)": {
      padding: "85px",
    },
  };

  const titleStyle = {
    color: "#fff",
    fontWeight: 800,
    margin: 0,
    "@media only screen and (min-width: 0px)": {
      fontSize: "28px",
    },
    "@media only screen and (min-width: 440px)": {
      fontSize: "36px",
    },
    "@media only screen and (min-width: 1150px)": {
      fontSize: "44px",
    },
    "@media only screen and (min-width: 1450px)": {
      fontSize: "44px",
    },
  };

  return (
    <div
      style={{
        backgroundColor: "#265660",
        padding: mobile ? "36px 0px 36px 0px" : "60px 0px 60px 0px",
      }}
      ref={serviceRef}
    >
      <div style={{ textAlign: "center" }}>
        <p css={titleStyle}>Våre tjenester</p>
      </div>
      {/*DIVIDER*/}
      <div style={{ padding: mobile ? "10px" : "20px" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div css={layoutPaddingStyles}></div>
        <div css={layoutStyles}>
          <TjenesteKort
            icon={<House />}
            text={pageText.ourServices.boligTakst}
            title="Boligtakst"
          />
          <TjenesteKort
            icon={<Compass />}
            text={pageText.ourServices.tegneoppdrag}
            title="Tegneoppdrag"
          />
          <TjenesteKort
            icon={<Edit />}
            text={pageText.ourServices.soknadstjenester}
            title="Søknadstjenester"
          />
          <TjenesteKort
            icon={<Lightning />}
            text={pageText.ourServices.energiraadgivning}
            title="Energirådgivning"
          />
        </div>
        <div css={layoutPaddingStyles}></div>
      </div>
    </div>
  );
};
export default VaareTjenester;
