const Email = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M5.33268 26.6666C4.59935 26.6666 3.97157 26.4055 3.44935 25.8833C2.92713 25.361 2.66602 24.7333 2.66602 23.9999V7.99992C2.66602 7.26659 2.92713 6.63881 3.44935 6.11659C3.97157 5.59436 4.59935 5.33325 5.33268 5.33325H26.666C27.3993 5.33325 28.0271 5.59436 28.5493 6.11659C29.0716 6.63881 29.3327 7.26659 29.3327 7.99992V23.9999C29.3327 24.7333 29.0716 25.361 28.5493 25.8833C28.0271 26.4055 27.3993 26.6666 26.666 26.6666H5.33268ZM15.9993 17.3333L5.33268 10.6666V23.9999H26.666V10.6666L15.9993 17.3333ZM15.9993 14.6666L26.666 7.99992H5.33268L15.9993 14.6666ZM5.33268 10.6666V7.99992V23.9999V10.6666Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default Email;
