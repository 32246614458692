/** @jsxImportSource @emotion/react */
import KontaktMatrise from "./KontaktMatrise";
import StylingContext from "../../contexts/StylingContext";
import { useContext } from "react";
import KontaktSkjema from "./KontaktSkjema";

const SnakkeSammen = () => {
  const { mobile, contactRef } = useContext(StylingContext);

  const layoutStyle = {
    display: "grid",
    justifyItems: "center",
    "@media only screen and (min-width: 0px)": {
      gap: "28px",
      padding: "32px 24px",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    "@media only screen and (min-width: 1150px)": {
      gap: "42px",
      padding: "60px 112px",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media only screen and (min-width: 1920px)": {
      gap: "56px",
      padding: "60px 144px",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  };

  const dividerStyle = {
    "@media only screen and (min-width: 0px)": {
      border: "1px solid #FFFFFF",
      opacity: "10%",
      width: "100%",
    },
    "@media only screen and (min-width: 1150px)": {
      display: "none",
    },
  };

  const layoutPaddingStyles = {
    "@media only screen and (min-width: 0px)": {
      padding: "8px",
    },
    "@media only screen and (min-width: 1150px)": {
      padding: "24px",
    },
    "@media only screen and (min-width: 1920px)": {
      padding: "85px",
    },
  };

  const titleStyle = {
    color: "#FFFFFF",
    fontWeight: 800,
    margin: 0,
    "@media only screen and (min-width: 0px)": {
      fontSize: "28px",
    },
    "@media only screen and (min-width: 440px)": {
      fontSize: "36px",
    },
    "@media only screen and (min-width: 1150px)": {
      fontSize: "44px",
    },
    "@media only screen and (min-width: 1450px)": {
      fontSize: "44px",
    },
  };

  const paragraphStyle = {
    color: "#FFFFFF",
    fontSize: `${mobile ? 18 : 21}px`,
    fontWeight: 300,
    lineHeight: "28px",
    margin: 0,
  };

  return (
    <div
      style={{
        backgroundColor: "#265660",
        display: "flex",
        justifyContent: "space-between",
      }}
      ref={contactRef}
    >
      <div css={layoutPaddingStyles}></div>
      <div css={layoutStyle}>
        <div>
          <p css={titleStyle}>Kontakt oss</p>
          <div style={{ padding: mobile ? "10px" : "20px" }}></div>
          <p css={paragraphStyle}>
            Vil du høre mer om våre tjenester? <br></br>Ring oss, eller send oss
            en mail så kommer vi tilbake til deg!
          </p>
          <div style={{ padding: mobile ? "10px" : "20px" }}></div>
          <KontaktMatrise />
        </div>
        <hr css={dividerStyle}></hr>
        <KontaktSkjema />
      </div>
      <div css={layoutPaddingStyles}></div>
    </div>
  );
};

export default SnakkeSammen;
