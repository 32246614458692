import Email from "../icons/Email";
import Telephone from "../icons/Telephone";
import Location from "../icons/Location";
import StylingContext from "../../contexts/StylingContext";
import { useContext } from "react";

const KontaktMatrise = () => {
  const { mobile } = useContext(StylingContext);

  return (
    <div style={{ color: "#FFFFFF" }}>
      <div id="Contact" style={{ marginBottom: mobile ? "20px" : "24px" }}>
        <div>
          <p
            style={{
              fontSize: `${mobile ? 21 : 24}px`,
              fontWeight: 600,
              margin: 0,
              marginBottom: mobile ? "12px" : "24px",
            }}
          >
            Kontakt
          </p>
          <div style={{ marginBottom: mobile ? "12px" : "16px" }}>
            <div style={{ display: "flex" }}>
              <Email />
              <p
                style={{
                  fontSize: `${mobile ? 18 : 21}px`,
                  fontWeight: 300,
                  lineHeight: "32px",
                  margin: 0,
                  marginLeft: "16px",
                }}
              >
                Magnus@boligtt.no
              </p>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Telephone />
            <p
              style={{
                fontSize: `${mobile ? 18 : 21}px`,
                fontWeight: 300,
                lineHeight: "32px",
                margin: 0,
                marginLeft: "16px",
              }}
            >
              93 98 98 98
            </p>
          </div>
        </div>
      </div>
      <div id="Address" style={{ marginBottom: mobile ? "20px" : "24px" }}>
        <div>
          <p
            style={{
              fontSize: `${mobile ? 21 : 24}px`,
              fontWeight: 600,
              margin: 0,
              marginBottom: mobile ? "12px" : "24px",
            }}
          >
            Addresse
          </p>
          <div style={{ display: "flex" }}>
            <Location />
            <p
              style={{
                fontSize: `${mobile ? 18 : 21}px`,
                fontWeight: 300,
                lineHeight: "32px",
                margin: 0,
                marginLeft: "16px",
              }}
            >
              Bjorhaugslettå 30, 4365 Nærbø
            </p>
          </div>
        </div>
      </div>
      <div id="OrganizationNumber">
        <div>
          <p
            style={{
              fontSize: `${mobile ? 21 : 24}px`,
              fontWeight: 600,
              margin: 0,
              marginBottom: mobile ? "12px" : "24px",
            }}
          >
            Organisasjonsnummer
          </p>
          <div style={{ display: "flex" }}>
            <Telephone />
            <p
              style={{
                fontSize: `${mobile ? 18 : 21}px`,
                fontWeight: 300,
                lineHeight: "32px",
                margin: 0,
                marginLeft: "16px",
              }}
            >
              92 74 56 30
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KontaktMatrise;
