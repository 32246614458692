const House = () => {
  return (
    <div
      style={{
        backgroundColor: "#ECF4F3",
        borderRadius: "3px",
        height: "40px",
        width: "40px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M9.99935 31.6667H14.9993V21.6667H24.9993V31.6667H29.9993V16.6667L19.9993 9.16667L9.99935 16.6667V31.6667ZM6.66602 35V15L19.9993 5L33.3327 15V35H21.666V25H18.3327V35H6.66602Z"
          fill="#265660"
        />
      </svg>
    </div>
  );
};

export default House;
